import { differenceInDays } from 'date-fns';
import { ActionTypes } from '.';
import { messageAndRedirectHelper } from '../../../src/constants/defaultValues';
import initialState from './initialState';
import { Actions, State } from './types';

const accountDeactivationReason = ({
  nullBillingData,
  timeStamp,
  isEmailConfirmed,
}) => {
  const endSubscriptionDate = new Date(timeStamp * 1000);
  const actualDate = new Date();

  const difInDays = differenceInDays(endSubscriptionDate, actualDate);

  const fifteenDaysTillPay = difInDays <= 15;

  return messageAndRedirectHelper(
    nullBillingData,
    fifteenDaysTillPay,
    isEmailConfirmed
  );
};

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.GET_STRIPE: {
      const { data } = action.payload;

      const accountDeactivationReasonObject = accountDeactivationReason(data);

      return accountDeactivationReasonObject;
    }
    default: {
      return state;
    }
  }
};
